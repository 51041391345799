<template>
    <div class="bg-white mb-3">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <h1 class="h4 mb-0">Create order</h1>
            </div>
        </div>
        <div class="d-flex flex-wrap section-container mb-4 pb-5">
            <div v-for="(section, index) of sections" v-bind:key="section.name" class="section mt-3" :class="{ 'section-active': index <= currentSectionIndex }">
                <span class="progress-circle">{{index + 1}}</span>
                <div>
                    {{section.label}}
                </div>
            </div>
        </div>
    </div>
    <form enctype="multipart/form-data" novalidate class="bg-white" @submit.prevent="submitOrder">
        <div v-if="currentSectionName == 'impressions'">
            <div class="row">
                <div class="col-12 col-md-4 mb-3">
                    <button class="impression-type-button btn btn-primary btn-block" type="button" @click="impressionTypeButton(2)">
                        <strong class="label">Physical impressions</strong>
                        <br />
                        (Alginate, silicone, etc)
                    </button>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <button class="impression-type-button btn btn-primary btn-block" type="button" @click="impressionTypeButton(1)">
                        <strong class="label">Digital impressions</strong>
                        <br />
                        (Intraoral scanner)
                    </button>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <button class="impression-type-button btn btn-primary btn-block" type="button" @click="impressionTypeButton(2)">
                        <strong class="label">No impressions</strong>
                        <br />
                        (Pre-cast or without impressions)
                    </button>
                </div>
            </div>
            <div><strong>Note:</strong> No separate lab ticket is required. A lab ticket will be generated for you (if required) once your order has been requested.</div>
        </div>
        <div v-if="currentSectionName == 'scans'">
            <div class="mb-2">
                <FileInput
                    label="Scans"
                    v-model="scansSection.scans"
                    :v="v$.scansSection.scans"
                    :allowedExtensions="['ply', 'stl']"
                />
            </div>
            <div class="buttons">
                <button class="btn btn-primary me-1" type="button" @click="nextButton">Next</button>
                <button class="btn btn-secondary me-1" type="button" @click="previousButton">Previous</button>
                <router-link class="btn btn-light" to="/orders">Cancel</router-link>
            </div>
        </div>
        <div v-if="currentSectionName == 'account'">
            <div class="row">
                <div class="col-md-6 mb-2">
                    <DropdownInput
                        label="Account"
                        v-model="accountSection.account_id"
                        :v="v$.accountSection.account_id"
                        :options="accounts"
                    />
                </div>
            </div>
            <div class="buttons">
                <button class="btn btn-primary me-1" type="button" @click="nextButton">Next</button>
                <button class="btn btn-secondary me-1" type="button" @click="previousButton">Previous</button>
                <router-link class="btn btn-light" to="/orders">Cancel</router-link>
            </div>
        </div>
        <div v-if="currentSectionName == 'basic'">
            <div class="row">
                <div v-if="preferences.showPatientName" class="col-sm-4 mb-2">
                    <TextInput
                        label="First name"
                        v-model="basicSection.first_name"
                        :v="v$.basicSection.first_name"
                    />
                </div>
                <div v-if="preferences.showPatientName" class="col-sm-4 mb-2">
                    <TextInput
                        label="Last name"
                        v-model="basicSection.last_name"
                        :v="v$.basicSection.last_name"
                    />
                </div>
                <div class="col-sm-4 mb-2">
                    <TextInput
                        label="Patient reference"
                        v-model="basicSection.patient_reference"
                        :v="v$.basicSection.patient_reference"
                    />
                  <div style="color: #737373">{{preferences.patientExplainer}}</div>
                </div>
                <div class="col-sm-5 col-md-4 mb-2">
                    <DropdownInput
                        label="Service level"
                        v-model="basicSection.standard"
                        :v="v$.basicSection.standard"
                        :options="standards"
                    />
                </div>
            </div>
            <div class="buttons">
                <button class="btn btn-primary me-1" type="button" @click="nextButton">Next</button>
                <button class="btn btn-secondary me-1" type="button" @click="previousButton">Previous</button>
                <router-link class="btn btn-light" to="/orders">Cancel</router-link>
            </div>
        </div>
        <div v-if="currentSectionName == 'products'">
            <div class="mb-2">
                <ProductInput
                    v-model="productsSection.products"
                    :v="v$.productsSection.products"
                    :service_level="basicSection.standard"
                    :accountId="this.accountSection.account_id"
                    :impressionType="this.impressionType"
                    :order_id="null"
                />
            </div>
            <div class="buttons">
                <button class="btn btn-primary me-1" type="button" @click="nextButton">Next</button>
                <button class="btn btn-secondary me-1" type="button" @click="previousButton">Previous</button>
                <router-link class="btn btn-light" to="/orders">Cancel</router-link>
            </div>
        </div>
        <div v-if="currentSectionName == 'instruction' && isAligner">
            <ToothChart v-bind="{ teethChartAnnotations:instructionSection.teethChartAnnotations}" ></ToothChart>
            <div class="row">
                <div class="col-sm-6 col-md-4 mb-2">
                    <DropdownInput
                        label="Treatment Type"
                        v-model="instructionSection.treatment_type"
                        :v="v$.instructionSection.treatment_type"
                        :options="instructionSection.treatment_types"
                        remove-empty-option="true"
                    />
                </div>
                <div class="col-sm-6 col-md-4 mb-2">
                    <DropdownInput
                        label="Arches"
                        v-model="instructionSection.arch"
                        :v="v$.instructionSection.arch"
                        :options="instructionSection.arches"
                        remove-empty-option="true"
                    />
                </div>
                <div class="col-sm-6 col-md-4 mb-2">
                    <DropdownInput
                        label="Trim Line"
                        v-model="instructionSection.trim_line"
                        :v="v$.instructionSection.trim_line"
                        :options="instructionSection.trim_lines"
                        remove-empty-option="true"
                    />
                </div>
                <div class="col-sd-6 col-md-4 mb-2">
                <DropdownInput
                    label="IPR"
                    v-model="instructionSection.ipr_request"
                    :v="v$.instructionSection.ipr_request"
                    :options="instructionSection.ipr_requested"
                    remove-empty-option="true"
                />
              </div>
              <div class="col-sm-6 col-md-4 mb-2">
                <DropdownInput
                    label="Attachments"
                    v-model="instructionSection.attachment"
                    :v="v$.instructionSection.attachment"
                    :options="instructionSection.attachments_needed"
                    remove-empty-option="true"
                />
              </div>
              
              
            </div>
            <div class="row">
              <div class="col-md-12 mb-2 mt-2">
                <TextareaInput
                    label="Patient Complaints"
                    v-model="instructionSection.complaint"
                    :v="v$.instructionSection.complaint"
                    rows="3"
                />
              </div>
                <div class="col-md-12 mb-2">
                    <TextareaInput
                        label="Other Notes/Instructions"
                        v-model="instructionSection.comment"
                        :v="v$.instructionSection.comment"
                        rows="3"
                    />
                </div>
            </div>
        </div>
        <div v-if="currentSectionName == 'instruction' && !isAligner">
            <div v-if="isRequiresShade" class="row">
                <div class="col-6 col-md-4 mb-2">
                    <TextInput
                        label="Shade"
                        v-model="instructionSection.shade"
                        :v="v$.instructionSection.shade"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 mb-2">
                    <TextareaInput
                        label="Instructions"
                        v-model="instructionSection.note"
                        :v="v$.instructionSection.note"
                        rows="6"
                    />
                </div>
            </div>
            <div class="row">
                <div class="mb-2">
                    <AnnotationInput
                        label="Notations"
                        v-model="instructionSection.annotations"
                        :v="v$.instructionSection.annotations"
                    />
                </div>
            </div>
            </div>
            <div v-if="currentSectionName == 'instruction'">
            <div class="mb-2">
                <FileInput
                    label="Photos"
                    v-model="instructionSection.photos"
                    :v="v$.instructionSection.photos"
                    :allowedExtensions="['jpg', 'jpeg', 'png', 'heic','dcm']"
                    :isAligner="isAligner"
                    :imageUploadGuide="{title: 'The following images should be included in your order',
                    images:[{ src: '/resources/image-upload-guide/aligner/teeth-front.webp', alt: 'Teeth front' },
                { src: '/resources/image-upload-guide/aligner/teeth-lh.webp', alt: 'Teeth left side' },
                { src: '/resources/image-upload-guide/aligner/teeth-rh.webp', alt: 'Teeth right side' },
                { src: '/resources/image-upload-guide/aligner/teeth-upper.webp', alt: 'Teeth upper' },
                { src: '/resources/image-upload-guide/aligner/teeth-lower.webp', alt: 'Teeth lower' }]}"

                />

              <div v-if="isAligner == true" class="mb-2 mt-2">
                <p>
                  <waiver
                      label="waiver"
                      v-model="instructionSection.waiver"
                      :v="v$.instructionSection.waiver"
                      type="checkbox"
                      id="disclaimer"
                  />
                </p>
              </div>
            </div>
            <div class="buttons">
                <button v-if="isAligner == true" class="btn btn-primary me-1" type="submit" :disabled="isSubmitting">Submit</button>
                <button v-if="isAligner == false" class="btn btn-primary me-1" type="button" @click="nextButton">Next</button>
                <button class="btn btn-secondary me-1" type="button" @click="previousButton">Previous</button>
                <router-link class="btn btn-light" to="/orders">Cancel</router-link>
            </div>
        </div>

        <div v-if="currentSectionName == 'delivery' && isAligner != true">
            <div class="row">
                <div class="col-sm-12 col-md-12 mb-2">
                    <DeliveryDateInput
                        label="Delivery date"
                        v-model="deliverySection.patient_date"
                        :v="v$.deliverySection.patient_date"
                        :account_id="this.accountSection.account_id"
                        :order_type="this.impressionType"
                        :standard="this.basicSection.standard"
                        :products="this.productsSection.products.map(x => x.id)"
                        @dates="this.dates = dates"
                    />
                </div>
            </div>
            <div class="buttons">
                <button v-if="!vouchers || !vouchers.length" class="btn btn-primary me-1" type="submit">Submit</button>
                <button v-if="vouchers && vouchers.length" class="btn btn-primary me-1" type="button" @click="nextButton">Next</button>
                <button class="btn btn-secondary me-1" type="button" @click="previousButton">Previous</button>
                <router-link class="btn btn-light" to="/orders">Cancel</router-link>
            </div>
        </div>
        <div v-if="currentSectionName == 'voucher'">
            <h5>Voucher</h5>
            <VoucherInput
                v-model="voucherSection.voucher"
                :v="v$.voucherSection.voucher"
                :vouchers="vouchers"
            />
            <div class="buttons">
                <button class="btn btn-primary me-1" type="submit">Submit</button>
                <button class="btn btn-secondary me-1" type="button" @click="previousButton">Previous</button>
                <router-link class="btn btn-light" to="/orders">Cancel</router-link>
            </div>
        </div>
    </form>
</template>

<style scoped lang="scss">
    .section-container
    {
        border-bottom: 1px solid $swift-lighter-grey;
    }

    .section
    {
        flex: 1 0 0%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 10px;

        .progress-circle
        {
            display: flex;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            border: 2px solid $swift-fresh-green;
            color: $swift-fresh-green;
            margin-bottom: 5px;
            align-items: center;
            justify-content: center;
            font-weight: bold;
        }
    }

    .section-active
    {
        .progress-circle
        {
            background-color: $swift-fresh-green;
            color: #FFFFFF;
        }
    }

    .buttons
    {
        padding-top: 20px;
    }

    .impression-type-button
    {
        padding: 40px;
        height: 100%;
        width: 100%;

        .label
        {
            font-size: 1.2em;
        }
    }

    #disclaimer {
        margin-right:10px;
    }

</style>

<script>
    import api from "@/services/api";
    import FileInput from '@/components/FileInput.vue';
    import TextInput from '@/components/TextInput.vue';
    import TextareaInput from '@/components/TextareaInput.vue';
    import DropdownInput from '@/components/DropdownInput.vue';
    import ProductInput from '@/components/ProductInput.vue';
    import VoucherInput from '@/components/VoucherInput.vue';
    import DeliveryDateInput from '@/components/DeliveryDateInput.vue';
    import AnnotationInput from '@/components/AnnotationInput.vue';
    import ToothChart from '@/components/ToothChart.vue';
    import useVuelidate from '@vuelidate/core'
    import {required, requiredIf, numeric, minLength, maxLength, helpers} from '@vuelidate/validators'
    import Waiver from "@/components/waiver.vue";

    const todayDate = () => {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return new Date(today.setDate(today.getDate()+1)); 
    };

    const rangeEndDate = () => {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return new Date(today.setMonth(today.getMonth()+1)); 
    };
    
    const isNotWeekend = (value) => { 
        var day = new Date(value).getUTCDay();

        if([6,0].includes(day)) {
            return false;
        }

        return true;
    };

    const isAfterToday = (value) => {
        var date = new Date(value);
        date.setHours(0, 0, 0, 0);
        return date.getTime() >= todayDate().getTime();
    };

    const isBeforeRangeEnd = (value) => {
        var date = new Date(value);
        date.setHours(0, 0, 0, 0);
        return date.getTime() <= rangeEndDate().getTime()
    };

    const validateReference = (regexParam) => {
        return helpers.withParams({regex: regexParam}, (value) => {
            return regexParam === null || value === null || value.length === 0 || value.match(new RegExp(regexParam)) !== null
        });
    };

    const isValidDelivery = (value,siblings) => {
      if(!siblings.delivery_date_invalid){
        return true;
      } else {
        return false;
      }
    };

    const IMPRESSION_TYPE_DIGITAL = 1,
        IMPRESSION_TYPE_PHYSICAL = 2;

    export default {
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data() {
            return {
                accounts: [],
                standards: [],
                vouchers: [],
                currentSectionIndex: 0,
                impressionType: null,
                scansSection: {
                    scans: [],
                },
                accountSection: {
                    account_id: null,
                },
                basicSection: {
                    first_name: '',
                    last_name: '',
                    patient_reference: '',
                    standard: '',
                },
                productsSection: {
                    products: [],
                },
                instructionSection: {
                    shade: '',
                    note: '',
                    annotations: [],
                    photos: [],

                    treatment_types: [],
                    treatment_type: 3,
                    arches: [],
                    arch: 0,
                    ipr_requested: [],
                    ipr_request: 2,
                    attachments_needed: [],
                    attachment: 2,
                    comment:'',
                    complaint: '',
                    teethChartAnnotations: [],
                    trim_lines: [],
                    trim_line: 0,
                    waiver: false,
                }, 
                deliverySection: {
                    patient_date: '',
                    delivery_date_invalid: null,
                },
                voucherSection: {
                    voucher: null,
                },
                dates: null,
                preferences: {
                    showPatientName: true,
                    patientRefRegex: null,
                    patientExplainer : ''
                },
                teethStates: [],
                isSubmitting: false
            }
        },
        computed: {
            sections() {
                var sections = [
                    {
                        name: 'impressions',
                        label: 'Impressions',
                        validation: 'impressionsSection',
                    },
                    {
                        name: 'scans',
                        label: 'Scans',
                        validation: 'scansSection',
                    },
                    {
                        name: 'account',
                        label: 'Account',
                        validation: 'accountSection',
                    },
                    {
                        name: 'basic',
                        label: 'Patient',
                        validation: 'basicSection',
                    },
                    {
                        name: 'products',
                        label: 'Products',
                        validation: 'productsSection',
                    },
                    {
                        name: 'instruction',
                        label: 'Instructions',
                        validation: 'instructionSection',
                    },
                    {
                        name: 'delivery',
                        label: 'Delivery',
                        validation: 'deliverySection',
                    },
                    {
                        name: 'voucher',
                        label: 'Voucher',
                        validation: 'voucherSection',
                    },
                ];

                if(this.impressionType == IMPRESSION_TYPE_PHYSICAL) // Physical
                {
                    sections = sections.filter(section => !['scans'].includes(section.name))
                }
                
                if(!this.vouchers.length) // No vouchers
                {
                    sections = sections.filter(section => !['voucher'].includes(section.name))
                }

                if (this.productsSection.products.filter(product => product.is_aligner).length > 0) {
                    sections = sections.filter(section => !['delivery'].includes(section.name));
                }

                return sections;
            },
            currentSection() {
                return this.sections[this.currentSectionIndex];
            },
            currentSectionName() {
                return this.currentSection.name;
            },
            isRequiresShade() {
                return this.productsSection.products.filter(product => product.requires_shade).length > 0;
            },
            isAligner() {
                return this.productsSection.products.filter(product => product.is_aligner).length > 0;
            }

        },
        validations () {
            return {
                scansSection: {
                    scans: { required },
                },
                accountSection: {
                    account_id: { required },
                },
                basicSection: {
                    first_name: { requiredIf: requiredIf(!this.basicSection.last_name && !this.basicSection.patient_reference) },
                    last_name: { requiredIf: requiredIf(!this.basicSection.first_name && !this.basicSection.patient_reference) },
                    patient_reference: {
                        requiredIf: requiredIf(!this.basicSection.first_name && !this.basicSection.last_name),
                        mustBeValidReference: helpers.withMessage('Reference is Invalid', validateReference(this.preferences.patientRefRegex)),
                    },
                    standard: { required },
                },
                productsSection: {
                    products: { required },
                },
                instructionSection: {
                    shade: { minLength: minLength(1), maxLength: maxLength(15), requiredIf: requiredIf(this.isRequiresShade) },
                    note: { maxLength: maxLength(1000) },
                    annotations: { maxLength: maxLength(100), },
                    photos: {  },

                    //alligner
                    treatment_type: { requiredIf: requiredIf(this.isAligner) },
                    ipr_request: { requiredIf: requiredIf(this.isAligner) },
                    attachment: { requiredIf: requiredIf(this.isAligner) },
                    arch: { requiredIf: requiredIf(this.isAligner) },
                    trim_line: { requiredIf: requiredIf(this.isAligner) },
                    comment: { maxLength: maxLength(1000) },
                    complaint: { maxLength: maxLength(1000) },
                    waiver: {requiredIf: requiredIf(function() {
                        return this.isAligner;
                    }), 
                    isTrue: function() {
                        return !this.isAligner || this.instructionSection.waiver === true;
                    }, 
                 },
                },
                deliverySection: {
                    patient_date: {
                        required,
                        isNotWeekend: helpers.withMessage('Date cannot be a weekend', isNotWeekend),
                        isAfterToday: helpers.withMessage('Date cannot be in the past', isAfterToday),
                        isBeforeRangeEnd: helpers.withMessage('Date must be in the next two months', isBeforeRangeEnd),
                        isValidDelivery: helpers.withMessage('Date is too soon', isValidDelivery),
                    },
                },
                voucherSection: {
                    voucher: { numeric },
                },
            }
        },
        components: {
          Waiver,
            TextInput,
            DropdownInput,
            FileInput,
            ProductInput,
            VoucherInput,
            TextareaInput,
            DeliveryDateInput,
            AnnotationInput,
            ToothChart,
        },
        mounted () {
            this.fetchAccounts();
            this.fetchStandards();
            this.fetchPracticePrefs();
            this.fetchAlignerOptions();
        },
        methods: {
            fullName: function (account) {
                return account.dentist.first_name + ' ' + account.dentist.surname + ' (' + account.code + ')';
            },
            fetchAccounts: function() {
                api
                .get('/api/v1/accounts', {
                    params: {
                        practice_id: this.$store.state.currentPracticeId,
                        bookingOnCheck: true,
                    }
                })
                .then((response) => {
                    this.accounts = response.data.data.map((account) => { return {
                        id: account.id,
                        label: this.fullName(account),
                    }});
                    if (this.accounts.length < 1)
                    {
                        alert(
                            'There are no accounts that can create an order' +
                            '\n' +
                            'If you think this is a mistake please contact us on 01204 323 323'
                        );
                    }
                })
                .catch(() => {
                    console.log('Catch accounts error');
                });
            },
            fetchStandards: function() {                
                this.standards = [{
                    id: 1,
                    label: 'NHS / Standard',
                }, {
                    id: 2,
                    label: 'Independent',
                }, {
                    id: 3,
                    label: 'Private',
                }]
            },
            fetchAlignerOptions: function() {                
                this.instructionSection.treatment_types = [ {
                    id: 3,
                    label: 'As Recommended',
                }, {
                    id: 0,
                    label: '3-3 Social Smile',
                }, {
                    id: 1,
                    label: '5-5 Pre molar to pre molar',
                }, {
                    id: 2,
                    label: '7-7 Full treatment',
                }];
                this.instructionSection.ipr_requested = [ {
                  id: 2,
                  label: 'As Recommended',
                }, {
                    id: 0,
                    label: 'Yes',
                }, {
                    id: 1,
                    label: 'No',
                }];
                this.instructionSection.attachments_needed = [{
                  id: 2,
                  label: 'As Recommended',
                }, {
                    id: 0,
                    label: 'Yes',
                }, {
                    id: 1,
                    label: 'No',
                }];
                this.instructionSection.arches = [{
                  id: 0,
                  label: 'Both',
                }, {
                    id: 1,
                    label: 'Upper',
                }, {
                    id: 2,
                    label: 'Lower',
                }];
                this.instructionSection.trim_lines = [{
                  id: 0,
                  label: 'As Recommended',
                },{
                  id: 1,
                  label: 'Full Scalloped',
                }, {
                  id: 2,
                  label: 'Straight (+1mm)',
                }, {
                  id: 3,
                  label: 'Straight (+2mm)',
                }];
            },
            fetchVouchers: function() {      
                this.vouchers = [];
                if(this.accountSection.account_id && this.productsSection.products && this.productsSection.products.length)
                {
                    api
                    .get('/api/v1/vouchers', {
                        params: {
                            account_id: this.accountSection.account_id,
                            products: this.productsSection.products.map(x => x.id),
                        }
                    })
                    .then((response) => {
                        this.vouchers = response.data;
                    })
                    .catch(() => {
                        console.log('Catch voucher error');
                    });   
                }     
            },
            submitOrder: async function () {

                if (this.isSubmitting) {
                    return; // If already submitting, exit the function
                }

                this.isSubmitting = true;

                var currentSectionValidator = this.currentSection.validation;
                this.deliverySection.delivery_date_invalid = null;
                await this.v$[currentSectionValidator].$reset();
                var isValid = await this.v$[currentSectionValidator].$validate();
                if(isValid)
                {
                    this.requestOrderCreation();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                    this.isSubmitting = false;
                }
            },
            orderRequestData: function() {
                var data = {
                    order_type: this.impressionType,
                    account_id: this.accountSection.account_id,
                    first_name: this.basicSection.first_name,
                    last_name: this.basicSection.last_name,
                    patient_reference: this.basicSection.patient_reference,
                    standard: this.basicSection.standard,
                    patient_date: this.deliverySection.patient_date,
                    note: this.instructionSection.note,
                    aligner_detail: {
                        treatment_type: this.instructionSection.treatment_type,
                        arch: this.instructionSection.arch,
                        attachment: this.instructionSection.attachment,
                        ipr_request: this.instructionSection.ipr_request,
                        comment: this.instructionSection.comment,
                        complaint: this.instructionSection.complaint,
                        trim_line: this.instructionSection.trim_line,
                    },
                    teeth_chart: this.instructionSection.teethChartAnnotations,
                    products: this.productsSection.products.map((product) => {
                        return {
                            id: product.id,
                            quantity: product.quantity,
                            material_id: product.material_id,
                        }
                    }),
                    is_aligner: this.isAligner,
                };

                // Supply scans data if digital impressions selected. 
                if(this.impressionType == IMPRESSION_TYPE_DIGITAL)
                {
                    data.scans = this.scansSection.scans.map(scan => scan.hash);
                }

                if(this.instructionSection.shade)
                {
                    data.shade = this.instructionSection.shade;
                }

                if(this.instructionSection.photos)
                {
                    data.files = this.instructionSection.photos.map(photo => photo.hash);
                }

                if(this.instructionSection.annotations)
                {
                    data.annotations = this.instructionSection.annotations.map((annotation) => {
                        var annotationReturn = {
                            file_id: annotation.hash,
                        };

                        if(annotation.text.length)
                        {
                            annotationReturn.text = annotation.text;
                        }

                        return annotationReturn;
                    });
                }

                if(this.voucherSection.voucher)
                {
                    data.voucher_id = this.voucherSection.voucher;
                }

                return data;
            },
            requestOrderCreation: function () {

                api.post('/api/v1/orders', this.orderRequestData())
                .then((response) => {
                    if(this.impressionType == IMPRESSION_TYPE_PHYSICAL)
                    {
                        this.$router.push({ name: 'OrderCreateSend', params: { 'id': response.data.id }});
                    }
                    else
                    {
                        this.$router.push({ name: 'PendingOrders' });
                    }
                })
                .catch(() => {
                    this.deliverySection.delivery_date_invalid = true;
                    var currentSectionValidator = this.currentSection.validation;
                    this.v$[currentSectionValidator].$validate();

                });
            },
            nextButton: async function() {
                var currentSectionValidator = this.currentSection.validation;
                
                var isValid = await this.v$[currentSectionValidator].$validate();

                if(isValid)
                {
                    this.currentSectionIndex++;

                    
                }
            },
            previousButton: async function() {
                this.currentSectionIndex--;

                if(this.currentSectionName === 'impressions')
                {
                    this.impressionType = null;
                }
            },
            impressionTypeButton: function(impressionType) {
                this.impressionType = impressionType;
                this.currentSectionIndex++;
            },
            setDates(dates) {
                this.dates = dates;
            },
            fetchPracticePrefs: function () {
                api
                    .get('/api/v1/practices/' + this.$store.state.currentPracticeId + '/preferences', {
                    })
                    .then((response) => {
                        this.preferences = {
                            showPatientName: response.data.data.providing_patient_names,
                            patientRefRegex: response.data.data.patient_ref_regex,
                            patientExplainer: response.data.data.patient_ref_explainer,
                        };
                    })
                    .catch((reason) => {
                        console.log('Catch practice preferences error');
                        console.log(reason);
                    });
            },
        },
        watch: {
            account_id() {
                this.fetchVouchers();
            },
            'productsSection.products': {
                handler() {
                    this.fetchVouchers();
                },
                deep: true    
            },
        },
    }

</script>
